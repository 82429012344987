import './module.public.layout.css';

require('../../lib/v3/bootstrap-toggle/js/bootstrap-toggle.min.js');
require('../../lib/v3/bootstrap-select/1.13.2/js/bootstrap-select.min.js');
require('../../lib/app/node_modules/velocity-animate');

import Util from '../../js/app/common/util';
window.Util = Util;

import Navbar from '../../js/app/common/navbar';
window.Navbar = Navbar;

import Facebook from '../../js/app/facebook/facebook.js';
window.Facebook = Facebook;

$(document).ready(function() {
    window.navbar = new Navbar();
    window.facebook = new Facebook();
});